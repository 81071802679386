<template>
  <div class="w-100">
    <a-result
      status="403"
      title="403"
      sub-title="Sorry, you are not authorized to access this page."
    />
  </div>
</template>

<script>
export default {
  name: '403Error',
  setup() {},
}
</script>
